import React from 'react'
import styled from 'styled-components'
// import EmailIcon from '@mui/icons-material/Email';
import { ContactInfo } from './components/ContactInfo';
import { BsFillTelephoneFill } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md';
import { ContactForm } from './components/ContactForm';
import {BsInstagram} from 'react-icons/bs';

const ContactSectionStyle = styled('section')`
  height: fit-content;
  width: 100%;
  background-color: white;
  color: white;
 
 p{
  color:  rgb(24 24 29);
  font-family: 'Work Sans', sans-serif;
  padding-top: 2rem;
  font-size: 4rem;
  margin-left: 16.5rem;
  text-transform: capitalize;
 }

 .contactSectionWrapper{
   display: flex;
   gap: 5rem;
   margin: 4rem 0;
   justify-content: space-between;
   position: relative;
   width: 79%;
   margin-left: auto ;
   margin-right: auto;
 }
 .contactSectionWrapper::after{
    position: absolute;
    content: '';
    width: 2px;
    height: 50%;
    background-color: black;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
 }
 .left {
    width: 100%;
    max-width: 500px;
  }
  .right {
    max-width: 500px;
    width: 100%;
    border-radius: 12px;
      }
`



// const EmailIconStyled = styled(EmailIcon)`


// `

export const Contact = () => {
    return (
        <ContactSectionStyle className='heading' id='contact'>
            <p>Get in touch</p>
            <div className="contactSectionWrapper">
                <div className="left">
                    <ContactInfo icon={< BsFillTelephoneFill size={17} />} text={'+91-8884453953'} />
                    <ContactInfo icon={< MdEmail size={20} />} text={'vsalimath@jayveerfoundation.org'} />
                    <ContactInfo icon={< BsInstagram size={20} />} text={''} />

                    {/* <ContactInfo icon={< BsFillTelephoneFill size={17}/>} text={'+91-8884453953'}/> */}
                </div>
                <div className="right">
                    <ContactForm />
                </div>
            </div>
        </ContactSectionStyle>
    )
}
