import React from 'react';
import styled from 'styled-components';

const ItemStyle = styled.div`
    padding: 0.6rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    border-radius: 20px;
    margin-bottom: 2rem;
    border: 1px solid #363636;
    .icon{
        color: black;
        padding: 1rem 1.2rem;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    .info{
        font-size: 1.6rem;
        color: black;

    }  letter-spacing: 0.5px;
`;

interface IContactInfo {
    icon: React.ReactElement<any>
    text: string;
}

export const ContactInfo: React.FC<IContactInfo> = (props) => {

    return (
        <ItemStyle>
            <div className="icon">{props.icon}</div>
            <div className="info">{props.text}</div>
        </ItemStyle>
    )
}
