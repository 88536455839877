import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const FooterWrapper = styled('div')`
   display: flex;
   justify-content: center;
   position: fixed;
   right: 0rem;
   top: 50%;
   transform: translate(-0,-50%);
   cursor: pointer;
`;

const Button = styled.button`
    all: unset;
    margin-left: 68rem;
    float: left;
    font-size: 2.5rem;
    text-decoration: none;
    background:#C4161C;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    color: #FFFFFF;
    transition: color 0.15s ease-in;
    text-align: center;
    padding: 1rem 3rem;
   
    .btn{
      font-size: 2.5rem;
      color: white;
    }

  &:hover,
  &:active {
    /* box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: 'rgba(0,0,0,0.16) 0px 1px 4px';
    transform: scale(0.96); */
  }
  `


export const Footer = () => {
  return (
    <FooterWrapper>
      <Link to="/blankPage" target='_blank' style={{ color: 'white' }}>
        <Button className='btn ex-btn'>Donate
        </Button>
      </Link>
    </FooterWrapper>
  )
}
