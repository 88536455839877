import { Grid } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { DonationCards } from './DonationCards';
import { FaMoneyBillWaveAlt } from 'react-icons/fa';
import {FaRupeeSign}  from 'react-icons/fa';
import {GiBrain} from 'react-icons/gi';
import {BiUserVoice} from 'react-icons/bi';

const DonationSectionContainer = styled.div`
    background-color: #F0F8FF;
    height: fit-content;
    padding: 2rem;
`;

const DonationSectionWrapper = styled.div`
   width: 80%;
   margin: auto;

   .heading{
        color:  rgb(24 24 29);
        font-family: 'Work Sans', sans-serif;
        /* padding-top: 2rem; */
        margin: auto;
        font-size: 4rem;
        text-transform: capitalize;
        padding: 2rem 0;
 }
`;

export const DonationSection = () => {
    return (
        <DonationSectionContainer id='explore'>
            <DonationSectionWrapper>
            <p className='heading'>Make A Difference</p>
                <Grid container item>
                    <Grid item xs={4}>
                        <DonationCards icon={< GiBrain size={190} />} btnText={'Learn'}text={'Learn more about the challenges facing communities & why our work is needed'} />
                    </Grid>
                    <Grid item xs={4}>
                        <DonationCards icon={< FaRupeeSign size={160} />} btnText={'Donate'} text={'You make our programs possible & your support is saving lives'} />
                    </Grid>
                    <Grid item xs={4}>
                        <DonationCards icon={< BiUserVoice size={190} />} btnText={'Act'} text={'Learn how to use your voice to help end violence & exploitation'} />
                    </Grid>
                </Grid>
            </DonationSectionWrapper>
        </DonationSectionContainer>
    )
}
