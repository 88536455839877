import React, { useRef } from 'react'
import styled from 'styled-components';
import emailjs from '@emailjs/browser';

const FormStyle = styled.form`
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;

   .form-group{
    width: 100%;
    margin-bottom: 2rem;
   }

   label{
    font-size: 1.8rem;
    color:  rgb(24 24 29);
   }
   input,textarea{
    width: 100%;
    font-size: 2rem;
    padding: 1.2rem;
    color: black;
    border: 1px solid #363636;
    border-radius: 20px;
    margin-top: 1rem;
    background-color: transparent;
}
   
   #message{
    min-height: 250px;
        resize: vertical;
   }
   #button{
        background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
        box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
        border: none;
        color: white;
        outline: none;
        font-size: 1.8rem;
        /* padding: 11px 26px 11px 26px; */
        font-family: 'Work Sans', sans-serif;
        text-transform: uppercase;    
        width: 100%;
        &:hover,
        &:active {
            box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
            box-shadow: 'rgba(0,0,0,0.16) 0px 1px 4px';
            transform: scale(0.96);
        } 

   }
   /* button[type="submit"]{
    background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
    box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
                    border-radius: 34px;
                    border: none;
                    color: white;
                    font-size: 1.8rem;
                    padding: 11px 26px 11px 26px;
                    font-family: 'Work Sans', sans-serif;
                    text-transform: uppercase;    
                    width: 100%;                            
          
   } */
`;


export const ContactForm = () => {
    // const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    // const [message, setMessage] = useState('');
    // const [result, setResult] = useState('');
    const form = useRef<any>();

    const sendEmail = (e: any) => {
        e.preventDefault();
        emailjs.sendForm('service_v0h47so', 'template_iam2b5b', form.current, 'WvlIESFdQXUcvSsW6')
            .then((result) => {
                console.log(result.text);
                console.log('message sent');
                
            }, (error) => {
                console.log(error.text);
                console.log('error');

            });
    };

    return (
        <div>
            <FormStyle ref={form} onSubmit={sendEmail} >
                <div className="form-group">
                    <label htmlFor="name" className="Name">{'Your Name'}</label>
                    <input type="text"  name='Name' />
                </div>
                <div className="form-group">
                    <label htmlFor="email" className="email">{'Your Email'}</label>
                    <input type="text"  name='email' />
                </div>
                <div className="form-group">
                    <label htmlFor="message" className="message">{'Message'}</label>
                    <input type="text" name='message' />
                </div>
                <input type="submit" id='button' value="send" />
                {/* <button type="submit">Send</button> */}
            </FormStyle>
        </div>
    );
};
