import React from 'react'
import styled from 'styled-components'
import { NavButtons } from './NavButtons'
import { Link } from 'react-router-dom'

export const Header = () => {
  return (
    <MainHeader>
      <HeaderWrapper>
        <Link to="/">
          <img src="./images/logo.svg" alt="logo" className='logo' />
        </Link>
        <NavButtons />
      </HeaderWrapper>
    </MainHeader>
  )
}

const MainHeader = styled.header`
  height: 13rem;
  background-color:rgb(249,249,255);
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 85%;
  margin: auto;
  position: fixed;
  z-index: 1;
  width: 100%;

  .logo{
    height: 13rem;
    max-width: 100%;
    padding-top: 1rem;
  }
`

const HeaderWrapper = styled.div`
  margin: auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;