import React from 'react'
import { Link } from 'react-scroll';
import styled from 'styled-components';

export const Hero = () => {
    return (
        <Wrapper id='hero'>
            <div className="container grid grid-two-column">
                <div className="section-hero-data">
                    <h1 className='hero-heading'>Jayveer Foundation</h1>
                    <p className='hero-subheading'>Building a Better Society by Society</p>
                    <p className='hero-para'>The greatest human connection is made when we give to each other. whether it's time, knowledge, expertise or a lifesaving donation.</p>
                    <Button className='btn ex-btn'>
                        <Link to="contact" spy={true} smooth={true} offset={50} duration={500}>Join Us</Link>
                    </Button>
                </div>
                <div className="section-hero-image">
                    <picture>
                        <img src='./images/bg.avif' alt='bg' className="hero-img" />
                    </picture>
                </div>
                {/* <Blur className="blur" style={{ background: '#d1ffc1', top: '18rem', width: '34rem', height: '25rem', left: '-0rem' }}></Blur>
                <Blur className="blur" style={{ background: 'rgb(233 210 255)'  }}></Blur>
                <Blur className="blur" style={{ background: 'rgb(233 210 255)' }}></Blur>
                <Blur className="blur" style={{ background: '#c1f5ff', top: '58rem', width: '34rem', height: '25rem', left: '51rem' }}></Blur> */}
            </div>
        </Wrapper>
    )
};

const Wrapper = styled.section`
    padding: 21rem 0 11rem 0;

    .section-hero-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
   
  .btn {
    max-width: 16rem;
  }

  .hero-heading {
    text-transform: uppercase;
    font-size: 6.4rem;
    letter-spacing: 1px;
  }

  .hero-subheading{
    margin-top: 1.5rem;
    max-width: 60rem;
    font-size: 3rem;
    letter-spacing: 1px;
  }
  .hero-para {
    margin-top: 1.5rem;
    margin-bottom: 3.4rem;
    max-width: 60rem;
    letter-spacing: 1px;
  }

  .section-hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  picture {
    text-align: center;
  }

  .hero-img {
    max-width: 90%;
    border-radius: 20px;
  }

  .blur{
  
  }
  @media (max-width: 786px) {
    .grid {
      gap: 7.2rem;
    }
  }

`

const Button = styled.button`
               background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
                box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
                border-radius: 34px;
                border: none;
                color: white;
                font-size: 1.8rem;
                padding: 11px 26px 11px 26px;
                font-family: 'Work Sans', sans-serif;
                text-transform: uppercase;  
                cursor: pointer;
              

  &:hover,
  &:active {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: 'rgba(0,0,0,0.16) 0px 1px 4px';
    transform: scale(0.96);
  }

  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
`;