import React from 'react';
import { DonationSection } from './components/DonationSection';
import { Footer } from './components/Footer';
import { Hero } from './components/Hero';
import { Contact } from './Contact';

export const Home = () => {
  return (
    <div>
      <Hero/>
      <DonationSection/>
      <Contact/>
      <Footer/>
    </div>
  )
}
