import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
   height: 100vh;
   background-color: #F0F8FF;
   display: flex;
   justify-content: center;
   align-items: center;

   h1{
    font-size: 6rem;
    color: black;
    letter-spacing: 1px;
    text-transform: uppercase;
   }
`;


export const BlankPage = () => {
  return (
    <Container>
        <h1>Work in Progress</h1>
    </Container>
  )
}
