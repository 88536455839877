import {  styled } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

const DonationCardsWrapper = styled('div')`
display: flex;
flex-direction: column;
justify-content: center;

align-items: center;
margin: auto;

    .imageWrapper{
        background-color: #5AAFE1;
        height: 270px;
        width: 270px;
        border-radius: 50%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 15px solid #86BEDE;
    }
    .icon{
        color: white;
        font-size: 2rem;
    }
`;

const Info = styled('p')`
    color: rgb(24 24 29);
    opacity: .7;
    font-size: 1.65rem;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
    padding: 1rem 0 2rem 0;
`;

const Button = styled('button')`
               background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
                box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
                border-radius: 34px;
                border: none;
                color: white;
                font-size: 1.8rem;
                padding: 11px 26px 11px 26px;
                font-family: 'Work Sans', sans-serif;
                text-transform: uppercase;
                text-align: center;           
                width: 150px;
                cursor: pointer;

  &:hover,
  &:active {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: 'rgba(0,0,0,0.16) 0px 1px 4px';
    transform: scale(0.96);
  };
`

interface IDonationCards {
    icon: React.ReactElement<any>
    text: string;
    btnText: string;
}


export const DonationCards: React.FC<IDonationCards> = (props) => {

    return (
        <DonationCardsWrapper>
            <div className='imageWrapper'>
                <div className="icon">{props.icon}</div>
            </div>
            <Info className="info">{props.text}</Info>
            <Link to="/blankPage" target='_blank'>
                <Button className='btn ex-btn'>
                    {props.btnText}
                </Button>
            </Link>
        </DonationCardsWrapper>
    )
}
