import React from 'react'
import { Link } from 'react-scroll';
import styled from 'styled-components'


export const NavButtons = () => {


    return (
        <Nav>
            <div>
                <ul className='navbar_list'>
                    <li>
                        {/* <Link to="hero" spy={true} smooth={true} offset={50} duration={500} className={'nav_link'} >Home</Link> */}
                    </li>
                    {/* <li>
                        <NavLink to='/gallary' className={'nav_link'}>Gallary</NavLink>
                    </li>*/}
                    <li>
                        {/* <Link to="explore" spy={true} smooth={true} offset={50} duration={500} className={'nav_link'}>Explore</Link> */}
                    </li> 
                </ul>
            </div>
        </Nav>
    )
}


const Nav = styled.nav`
  .navbar_list{
    display: flex;
    list-style: none;
    gap: 4.8rem;
  
       li {
        list-style: none;
            .nav_link{
                &:link,
                &:visited {
                    display: inline-block;
                    text-decoration: none;
                    font-size: 2rem;
                    text-transform: uppercase;
                    color: #212529;
                    transition: color 0.3s linear;
                    font-family: 'Work Sans', sans-serif;
                    
                }
                &:hover,
                &:active{
                     color: #5AB4E5;
                }
            }
            .contact{
                background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
                box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
                border-radius: 34px;
                border: none;
                color: white;
                font-size: 1.8rem;
                padding: 11px 26px 11px 26px;
                font-family: 'Work Sans', sans-serif;
                text-transform: uppercase;                

  &:hover,
  &:active {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: 'rgba(0,0,0,0.16) 0px 1px 4px';
    transform: scale(0.76);
  }

  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
            }
       
       } 
    } 
`;
