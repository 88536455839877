import React from 'react';
import { Home } from './Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Header } from './components/Header';
import { BlankPage } from './BlankPage';

const App: React.FC = () => {

    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/blankPage' element={<BlankPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
